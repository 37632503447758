import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const messages = {
  'en':{
    auth:{
      reg:'Registration',
      login:'Login',
      nick:'Nick',
      pass:'Password',
      confirm:'Confirm',
      send:'Send',
      valid:{
        nick:'Enter nick',
        pass:'Enter password min. 8 characters',
        confirm:'Password mismatch',
      },
      tg:{
        login_button:'Sign in with Telegram',
        phone:'Phone number',
        code:'Code from telegram',
      },
    },
    //myprofile
    status:'Status',
    site:'Site',
    site_title:'If a site is listed here, it opens when other users click on your avatar on the map.',
    ava:'Download avatar',
    delete_user:'Delete user',
    delete_ava:'Delete avatar',
    settings:{
      settings:'Settings map',
      centered:'I\'m centered on the map',
      open_site:'Open user sites from the map',
    },
    profile:{
      delete_user:'Delete from Featured',
      add_user:'Add to Featured',
      on_map:'View on map',
      to_site:'Go to site',
    }
  },
  'ru':{
    auth:{
      reg:'Регистрация',
      login:'Войти',
      nick:'Ник',
      pass: 'Пароль',
      confirm:'Подтверждение',
      send:'Отправить',
      valid:{
        nick:'Введите ник',
        pass:'Введите пароль мин. 8 символов',
        confirm:'Пароли не совпадают',
      },
      tg:{
        login_button:'Войти через Telegram',
        phone:'Номер телефона',
        code:'Код из телеграмма',
      },
    },
    //myprofile
    status:'Статус',
    site:'Сайт',
    site_title:'Если здесь указан сайт, он открывается, когда другие пользователи нажимают на ваш аватар на карте.',
    ava:'Загрузить аватар',
    delete_user:'Удалить юзера',
    delete_ava:'Удалить аватар',
    settings: {
      settings:'Настройки карты',
      centered: 'Центрировать меня на карте',
      open_site: 'Открывать сайт при клике на юзера',
    },
    profile:{
      delete_user:'Удалить из избранных',
      add_user:'Добавить в избранные',
      on_map:'Увидеть на карте',
      to_site:'Перейти на сайт',
    }
  }
};

const locale = localStorage.mapa_locale!==undefined?localStorage.mapa_locale:'ru'
const i18n = new VueI18n({
  locale:locale,
  messages
});
export default i18n;


