import {TgMapStore} from "./store";


let socket = new WebSocket(localStorage.socket);

// socket.onopen = function(e) {
//   console.log("Соединение установлено");
// };

// socket.onmessage = function(event) {
//   const tgMapStore = TgMapStore()
//   const user = JSON.parse(event.data)
//   // console.log(user)
//   if(user.lat === ""){
//     tgMapStore.addUser(user)
//   }else{
//     tgMapStore.updateUser(user)
//   }
// };

socket.onclose = function(event) {
  if (event.wasClean) {
    console.log(`Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
  } else {
    // например, сервер убил процесс или сеть недоступна
    // обычно в этом случае event.code 1006
    console.log('Соединение прервано')
    socket.onopen = function(e) {
      console.log("Соединение установлено заново");
    };
  }
};

socket.onerror = function(error) {
  console.log(error);
};

export default socket;
