<template>
  <div class="main">
<!--    <div>{{marker}}</div>-->
    <h1>{{marker.info}}</h1>
    <h2 v-if="photos.length!==0">{{photos[0].text}}</h2>

    <div v-if="photos.length!==0" class="img_container" v-for="photo of photos">
      <div class="img_div">
        <img width="130" class="img" :src="photo.url" data-fancybox="gallery">
      </div>
    </div>

    <div v-if="marker.owner === me">
<!--      <FileUpload name="demo[]" url="./upload.php" @upload="onUpload" :multiple="true" accept="image/*" :maxFileSize="1000000">-->
<!--        <template #empty>-->
<!--          <p>Drag and drop files to here to upload.</p>-->
<!--        </template>-->
<!--      </FileUpload>-->
      <Button @click="deleteMarker" class="p-button-danger" style="margin-top: 0.67em">Удалить маркер</Button>
    </div>

  </div>
</template>

<script>
import tg from "../telegram/tg";
import {TgMapStore} from "../store";
import {useVuelidate} from "@vuelidate/core";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default {
  name: "staticMarker",
  components:{},
  data(){
    return{
      me:localStorage.me,
      photos:[],
    }
  },
  setup() {
    Fancybox.bind("[data-fancybox]", {
        contentDblClick:"zoomToMax"
    });
    const tgMapStore = TgMapStore()
    const marker = tgMapStore.marker
    return {tgMapStore,marker,v$:useVuelidate()}
  },
  mounted(){
    this.initMarker();
  },
  methods:{
    async initMarker(){


      if(this.marker.photos[0].id!==""){
        this.photos = await tg.getPhotosByDateAndId(this.marker.photos)
      }


      // for(const mes of this.marker.photos){
      //   const res = await tg.getPhotoByMesId(mes.id)
      //   console.log(res)
      //   this.photos.push(res)
      // }

      // if(localStorage.login === 'site') {
      //   this.$http.post(localStorage.getItem("s") + '/app/profile/', {nick: this.nick, me: this.me}).then((res) => {
      //     const data = res.data
      //     console.log(data)
      //     this.img = localStorage.getItem('s') + data.image;
      //     this.status = data.status;
      //     this.chek = data.chek
      //     this.page = data.page
      //   });
      // }else if(localStorage.login === 'tg'){
      //   if(this.nick.includes("fake-user")){
      //     this.img = localStorage.s+'/images/default.jpg'
      //   }else {
      //     this.img = await tg.getUserPhoto(await tg.getUser(this.nick))
      //   }
      // }
    },
    deleteMarker(){
      this.$http.post(localStorage.getItem("s")+'/tg/markers/', {marker_id:this.marker.id, del_marker:'OK'}).then((res) => {
          console.log(res)
          this.$emit('tg');
      });
    },
    getPos(){
      this.$http.post(localStorage.getItem("s")+'/app/getPos/', {nick:this.nick})
        .then((response) => {
          console.log(response.data)
          if (response.data.online==true){
            let lat=response.data.lat;
            let lng=response.data.lng;
            localStorage.setItem('lat',lat)
            localStorage.setItem('lng',lng)
            localStorage.setItem('zoom','19')
            localStorage.setItem('getpos', true)
            this.$emit('map');
          }else{
            alert("User is offline!")
          }
        })
        .then((response)=>{
          localStorage.removeItem('getpos')
        });
    },
  },
}
</script>

<style scoped>
.img{
  padding: 10px;
}
.img_div{
  border: 1px solid #bbbbbb;
  cursor: pointer;
}
.img_div:hover{
  background: #bbbbbb;
}
.img_container{
  display: inline-block;
}
</style>
