<template>
<div>
  <div id="map"></div>
</div>
</template>

<script>
  //import '../../node_modules/leaflet/leaflet-src.js'
  // import '../../node_modules/assets/leaflet/locatecontrol/L.Control.Locate.min.css'
  // import '../../node_modules/assets/leaflet/locatecontrol/L.Control.Locate.min.js'
  // import '../../node_modules/assets/leaflet/realtime/leaflet-realtime.min.js'
  // import '../../node_modules/assets/leaflet/prunecluster/PruneCluster.js'
  import '../../public/assets/leaflet/leaflet.css'
  import '../../public/assets/leaflet/leaflet.js'
  import '../../public/assets/leaflet/markercluster/MarkerCluster.css'
  import '../../public/assets/leaflet/markercluster/MarkerCluster.Default.css'
  import '../../public/assets/leaflet/markercluster/leaflet.markercluster.js'
  // if (!this._map) {return}

export default {
	data(){
		return {
		  me:localStorage.getItem("me"),
      map:null,
      tileLayer:null,
      showModal:false,
      mypos: localStorage.getItem("mypos")!=undefined ? localStorage.getItem("mypos") : true,
      lat:localStorage.getItem("lat")!=undefined ? localStorage.getItem("lat") : 53.9045398,
      lng:localStorage.getItem("lng")!=undefined ? localStorage.getItem("lng") : 27.5615244,
      zoom:localStorage.getItem("zoom")!=undefined ? localStorage.getItem("zoom") : 10,
      searchControl:null,
		}
	},
	mounted() {
    console.log('map')
    this.initMap();
    this.initMoov();
    this.initLocation();
  },
	methods: {
    initMoov(){
      this.map.on("moveend", this.getMoov);
    },
    getMoov(e){
      let zoom = this.map.getZoom();
      localStorage.setItem("zoom", zoom);
      let center = this.map.getCenter();
      localStorage.setItem("lat", center.lat);
      localStorage.setItem("lng", center.lng);
    },
    initMap() {
      let latlng=[this.lat,this.lng]
      this.map = L.map('map').setView(latlng, this.zoom);//[53.9045398,27.5615244]
      this.tileLayer = L.tileLayer ('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
              maxZoom: 19,
              minZoom: 0,
              // attribution: '<a target="_blank" href="http://www.leafletjs.com">Leaflet</a> | © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
              attribution: ' © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }
      );
      this.tileLayer.addTo(this.map);
    },
    initLocation() {
      let x;
      if(localStorage.getItem('getpos')==undefined){
        if (this.mypos=='false'){x=false}else{x=true};
      }
      this.map.locate({maxZoom: this.zoom, setView: x});//watch:true - fo real time, setView:true - see locate, maxZoom: this.zoom
      if (this.me!=undefined){
        this.map.on('locationfound', this.onLocationFound);
      }
    },
    onLocationFound(e){
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };
      this.$http.post(localStorage.getItem("s")+'/app/locate/', {lat:e.latlng.lat, lng:e.latlng.lng, me:this.me}, config).then((response)=>{
         if (response.data!='error') {
           console.log(response)
           let markers = L.markerClusterGroup({});//создаем группу кластеризации
           for (let i = 0; i < response.data.length; i++) {
             let icon = L.icon({
               iconUrl:localStorage.getItem('s')+response.data[i].image,
               iconSize:[30,30],
               className:'icon',
             });
             let user = response.data[i].nick;
             let page = response.data[i].page;
             let status = response.data[i].status;
             let info = status?status:user;
              //  let info = String(document.write('<br>'))
             let marker = L.marker([response.data[i].latitude, response.data[i].longitude], {icon:icon});
             marker.bindTooltip(info).openTooltip();// окошко имя юзера
             marker.on('click', onClick.bind(this).bind(user));
             function onClick(e){
               if (user!=this.me){
                  if (localStorage.getItem("openHot")=="0"){
                    localStorage.setItem("user", user);
                    this.$emit('profile')
                  }else{
                    if (page===""){
                      localStorage.setItem("user", user);
                      this.$emit('profile')
                    }else{
                      if (!page.match(/^https?:\/\//i)) {
                        page = 'http://' + page;
                      }
                      window.open(page, '_blank');
                    }
                  }
                }
               else {
                 this.$emit('myprofile')
               }
              }
             markers.addLayer(marker);//добавляем маркеры в группу
           }
           this.map.addLayer(markers);//добавляем группу на карту
        }
      });
    }
	},
}
</script>

<style>
  html,
  body {
    height: 100%;
  }
	#map {
  height: calc(100% - 50px);
  width: 100vw;
}
  .icon{
    background: deepskyblue;
    border-radius: 50%;
  }

  #mapSearchContainer{
  position:fixed;
  top:20px;
  right: 40px;
  height:30px;
  width:180px;
  z-index:110;
  font-size:10pt;
  color:#5d5d5d;
  border:solid 1px #bbb;
  background-color:#f8f8f8;
}
.pointer{
  position:absolute;
  /*top:86px;*/
  top:140px;
  left:60px;
  z-index:99999;
}
  /*.icon{*/
  /*  border-radius: 50%;*/
  /*  height: 30px;*/
  /*  width: 30px;*/
  /*  object-fit: cover;*/
  /*}*/
  /*.icon:hover{*/
    /*height: 100px;*/
  /*}*/
</style>
