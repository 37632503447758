<template>
  <div :key="componentKey">
    <div id="map"></div>
  </div>
</template>

<script>
//import axios from 'axios'
// import '../../node_modules/leaflet/leaflet.css'
// import '../../node_modules/leaflet/locatecontrol/L.Control.Locate.min.css'
// import '../../node_modules/leaflet/markercluster/MarkerCluster.css'
// import '../../node_modules/leaflet/markercluster/MarkerCluster.Default.css'
// import '../../node_modules/leaflet/leaflet-src2.js'
// import '../../node_modules/leaflet/locatecontrol/L.Control.Locate.min.js'
// import '../../node_modules/leaflet/markercluster/leaflet.markercluster.js'
//import '../../assets/leaflet/realtime/leaflet-realtime.js'
// import '../../assets/leaflet/markercluster/MarkerCluster.css'
// import '../../assets/leaflet/markercluster/MarkerCluster.Default.css'
// import '../../assets/leaflet/markercluster/leaflet.markercluster.js'
import '../../public/assets/leaflet/leaflet.js'
import '../../public/assets/leaflet/leaflet.css'
import { PruneCluster, PruneClusterForLeaflet } from 'exports-loader?PruneCluster,PruneClusterForLeaflet!prunecluster/dist/PruneCluster.js'

export default {
  data(){
    return {
      me:localStorage.getItem("me"),
      map:null,
      tileLayer:null,
      showModal:false,
      mypos: localStorage.getItem("mypos")!==undefined ? JSON.parse(localStorage.getItem("mypos")) : true,
      lat:localStorage.getItem("lat")!==undefined ? localStorage.getItem("lat") : 53.9045398,
      lng:localStorage.getItem("lng")!==undefined ? localStorage.getItem("lng") : 27.5615244,
      zoom:localStorage.getItem("zoom")!==undefined ? localStorage.getItem("zoom") : 10,
      socket:null,
      latpos:null,
      lngpos:null,
      img:null,
      isLocate:false,
      componentKey: 0,
      myLat:null,
      myLng:null,
      targetLatLngs: [],
      duration: 2000, // Длительность анимации в миллисекундах
      startTime: null,
      leafletView: null,
      markerAnimations:{},
    }
  },
  created() {
    if (!this.isLocate) {
      this.forceRerender()
    }
  },
  mounted() {
    console.log('WS')
    this.socket = new WebSocket(localStorage.socket)
    this.initMap();
    this.initMoov();
    setTimeout(function(){this.initLocation()}.bind(this),1000);
    setTimeout(function(){this.getMarkers()}.bind(this),2000);
    // setTimeout(function(){
    //   setInterval(this.markerMovementIllusion, 3000);
    // }.bind(this),4000);
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    initMoov(){
      this.map.on("moveend", this.getMoov);
    },
    getMoov(e){
      let zoom = this.map.getZoom();
      // let currentDate = new Date();
      // let hours = currentDate.getHours();
      // let minutes = currentDate.getMinutes();
      // let seconds = currentDate.getSeconds();
      // console.log(zoom+' - '+hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds)
      localStorage.setItem("zoom", zoom);
      let center = this.map.getCenter();
      localStorage.setItem("lat", center.lat);
      localStorage.setItem("lng", center.lng);
    },
    initMap() {
      let latlng = [this.lat,this.lng]
      if (this.map) {
        this.map.remove();
      }
      this.map = L.map ('map'). setView (latlng, localStorage.getItem("zoom"));//[53.9045398,27.5615244]
      this.tileLayer = L.tileLayer ('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
          maxZoom: 19,
          attribution: '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }
      );
      this.tileLayer.addTo(this.map);
      this.map.on('dblclick', this.initMagazine)
    },
    initLocation() {
      this.map.locate({setView: localStorage.getItem('getpos')===undefined?this.mypos:false, maxZoom: localStorage.getItem("zoom"), watch:true, enableHighAccuracy:true});//watch:true - fo real time, setView:true - see locate, maxZoom: this.zoom
      if (this.map) {
        this.map.off('locationfound', this.onLocationFound);
      }
      this.map.on('locationfound', this.onLocationFound);
    },
    onLocationFound(e){
      this.myLat=e.latlng.lat
      this.myLng=e.latlng.lng
      const data = {
        lat: e.latlng.lat,
        lng: e.latlng.lng,
        me: this.me,
        img: this.img,
      };
      if (!this.isLocate) {
        this.locate(data)
        this.isLocate = true;
      }
      this.socket.send(JSON.stringify(data))
    },
    locate(data){
      this.$http.post(localStorage.getItem('s')+'/app/locateWS/', data)
    },
    markerMovementIllusion(){
      this.myLat+=0.0001
      const data = {
        lat: this.myLat,
        lng: this.myLng,
        me: this.me,
        img: this.img,
      };
      this.socket.send(JSON.stringify(data))
    },
    getMarkers() {
      let t = this;
      t.$http.post(localStorage.getItem('s') + '/app/getMarkers/').then(function (r) {
        t.leafletView = new PruneClusterForLeaflet();
        let markers = [];
        for (let i = 0; i < r.data.length; i++) {
          let data = r.data[i];
          let marker = new PruneCluster.Marker(data.latitude, data.longitude);
          marker.data.info = data.status ? data.status : data.nick;
          marker.data.user = data.nick;
          marker.data.page = data.page;
          marker.data.icon = L.icon({
            iconUrl: localStorage.getItem('s') + data.image,
            iconSize: [30, 30],
          });
          console.log(marker)
          t.leafletView.PrepareLeafletMarker = function (leafletMarker, data) {
            leafletMarker.setIcon(data.icon);
            leafletMarker.bindTooltip(data.info);
            leafletMarker.on('click', function () {
              console.log(data.user)
              if (data.user !== t.me) {
                if (localStorage.getItem("openHot") === "0") {
                  localStorage.setItem("user", data.user);
                  t.$emit('profile');
                } else {
                  if (data.page === '') {
                    localStorage.setItem("user", data.user);
                    t.$emit('profile');
                  } else {
                    if (!data.page.match(/^https?:\/\//i)) {
                      data.page = 'https://' + data.page;
                    }
                    window.open(data.page, '_blank');
                  }
                }
              } else {
                t.$emit('myprofile');
              }
            });
          };
          markers.push(marker);
          t.leafletView.RegisterMarker(marker);
          if(marker.data.user === t.me){
            t.clickAnimation(marker)
          }
        }
        t.socket.onmessage = function (event) {
          let msg = JSON.parse(event.data);
          markers.forEach(function (m) {
            if (m.data.user === msg.me) {
              // t.startTime=null;
              // t.targetLatLngs.push([msg.lat, msg.lng]);
              let currentLatLng = [m.position.lat, m.position.lng];
              let targetLatLng = [msg.lat, msg.lng];
              if (msg.me === t.me && t.mypos) {
                requestAnimationFrame(t.animateMarkerWrapper(m, null, currentLatLng, targetLatLng));
              }else{
                requestAnimationFrame(t.animateMarkerWrapperNoCamera(m, null, currentLatLng, targetLatLng));
              }
            }
          });
          // if (msg.me === t.me && t.mypos) {
          //   let currentZoom = t.map.getZoom();
          //   t.map.setView([msg.lat, msg.lng], currentZoom);
          // }
          t.leafletView.ProcessView();
        };
        t.map.addLayer(t.leafletView);
      });
    },
    animateMarkerWrapper(marker, startTime, currentLatLng, targetLatLng) {
      let t = this;
      return function (timestamp) {
        // console.log(startTime)
        if (!startTime) {
          startTime = timestamp;
        }
        let progressFraction = (timestamp - startTime)/t.duration
        if (progressFraction < 1) {
          let interpolatedLatLng = [
            currentLatLng[0] + (targetLatLng[0] - currentLatLng[0]) * progressFraction,
            currentLatLng[1] + (targetLatLng[1] - currentLatLng[1]) * progressFraction
          ];
          marker.position.lat = interpolatedLatLng[0];
          marker.position.lng = interpolatedLatLng[1];
          t.map.setView([interpolatedLatLng[0], interpolatedLatLng[1]], t.map.getZoom());
          t.leafletView.ProcessView();
          requestAnimationFrame(t.animateMarkerWrapper(marker, startTime, currentLatLng, targetLatLng));
        }
        // else {
        //   console.log('1')
        //   currentLatLng = targetLatLng;
        //   t.startTime = null;
        //   // if (t.targetLatLngs.length > 0) {
        //   //   console.log('2')
        //   //   targetLatLng = t.targetLatLngs.shift();
        //   //   requestAnimationFrame(t.animateMarkerWrapper(marker, currentLatLng, targetLatLng));
        //   // }
        // }
      };
    },
    animateMarkerWrapperNoCamera(marker, startTime, currentLatLng, targetLatLng) {
      let t = this;
      return function (timestamp) {
        if (!startTime) {
          startTime = timestamp;
        }
        let progressFraction = (timestamp - startTime)/t.duration
        if (progressFraction < 1) {
          marker.position.lat = currentLatLng[0] + (targetLatLng[0] - currentLatLng[0]) * progressFraction;
          marker.position.lng = currentLatLng[1] + (targetLatLng[1] - currentLatLng[1]) * progressFraction;
          t.leafletView.ProcessView();
          requestAnimationFrame(t.animateMarkerWrapperNoCamera(marker, startTime, currentLatLng, targetLatLng));
        }
      };
    },
    clickAnimation(m){
      let t = this
      t.map.on('click', function(event) {
        let targetLatLng = [event.latlng.lat, event.latlng.lng];
        let currentLatLng = [m.position.lat, m.position.lng];
        // if (t.markerAnimations[m.data.user]) {
        //   console.log('OK')
        //   cancelAnimationFrame(t.markerAnimations[m.data.user]);
        // }
        // t.markerAnimations[m.data.user] = requestAnimationFrame(t.animateMarkerWrapper(m, null, currentLatLng, targetLatLng));
        if (m.data.user === t.me && t.mypos) {
          requestAnimationFrame(t.animateMarkerWrapper(m, null, currentLatLng, targetLatLng));
        }else{
          requestAnimationFrame(t.animateMarkerWrapperNoCamera(m, null, currentLatLng, targetLatLng));
        }
      });
    },
    // getMarkers() {
    //     let t=this
    //     t.$http.post(localStorage.getItem('s')+'/app/getMarkers/').then(function (response) {
    //       let leafletView = new PruneClusterForLeaflet()
    //       let markers = []
    //       for (let i = 0; i < response.data.length; i++) {
    //         let data = response.data[i];
    //         let marker = new PruneCluster.Marker(data.latitude, data.longitude);
    //         marker.data.info = data.status?data.status:data.nick
    //         marker.data.user = data.nick
    //         marker.data.page = data.page
    //         marker.data.icon = L.icon({
    //           iconUrl:localStorage.getItem('s')+data.image,
    //           iconSize:[30,30],
    //         });
    //         leafletView.PrepareLeafletMarker = function(leafletMarker, data) {
    //           leafletMarker.setIcon(data.icon);
    //           leafletMarker.bindTooltip(data.info)
    //           leafletMarker.on('click', function(){
    //             if (data.user!==t.me){
    //               if (localStorage.getItem("openHot")==="0"){
    //                 localStorage.setItem("user", data.user);
    //                 t.$emit('profile')
    //               }else{
    //                 if (data.page===''){
    //                   localStorage.setItem("user", data.user);
    //                   t.$emit('profile')
    //                 }else{
    //                   if (!data.page.match(/^https?:\/\//i)) {
    //                     data.page = 'https://' + data.page;
    //                   }
    //                   window.open(data.page, '_blank');
    //                 }
    //               }
    //             }
    //             else {
    //               t.$emit('myprofile')
    //             }
    //           });
    //         };
    //         markers.push(marker)
    //         leafletView.RegisterMarker(marker);
    //       }
    //
    //       // let targetLatLngs = []; // Список координат для маркера
    //
    //       t.socket.onmessage = function(event) {
    //         let msg = JSON.parse(event.data);
    //         for (let i = 0; i < markers.length; i++) {
    //           let m=markers[i]
    //           if (m.data.user === msg.me){
    //             t.targetLatLngs.push([msg.lat, msg.lng]);
    //             // m.position.lat = msg.lat
    //             // m.position.lng = msg.lng
    //
    //             // if (this.targetLatLngs.length === 1) {
    //               let currentLatLng = [m.position.lat, m.position.lng]
    //               let targetLatLng = t.targetLatLngs.shift();
    //               // startTime = null;
    //               let animatedFunction = t.animateMarkerWrapper(m, currentLatLng, targetLatLng);
    //               requestAnimationFrame(animatedFunction);
    //             // }
    //           }
    //         }
    //         if (msg.me === t.me && t.mypos){
    //           let currentZoom = t.map.getZoom();
    //           t.map.setView([msg.lat, msg.lng], currentZoom);
    //         }
    //         leafletView.ProcessView();
    //       }
    //       t.map.addLayer(leafletView);
    //     })
    // },
    // animateMarkerWrapper(marker, currentLatLng, targetLatLng){
    //   let t = this
    //   return function(timestamp) {
    //
    //     if (!t.startTime) {
    //       t.startTime = timestamp;
    //     }
    //
    //     console.log(timestamp)
    //     let progress = timestamp - t.startTime;
    //     console.log(progress)
    //     let progressFraction = progress / t.duration;
    //     console.log(progressFraction)
    //
    //     if (progressFraction < 1) {
    //       console.log('ok')
    //       // Интерполяция между текущими и новыми координатами
    //       marker.position.lat = currentLatLng.lat + (targetLatLng[0] - currentLatLng.lat) * progressFraction
    //       marker.position.lng =  currentLatLng.lng + (targetLatLng[1] - currentLatLng.lng) * progressFraction
    //
    //       // Запланировать следующий кадр анимации
    //       // requestAnimationFrame(t.animateMarkerWrapper);
    //
    //     } else {
    //       // Анимация завершена, обновить текущие координаты маркера
    //       currentLatLng = targetLatLng;
    //       t.startTime = null;
    //
    //       // Если есть следующие координаты, начать анимацию к ним
    //       // if (this.targetLatLngs.length > 0) {
    //       //   targetLatLng = this.targetLatLngs.shift();
    //       //   requestAnimationFrame(this.animateMarkerWrapper);
    //       // }
    //     }
    //   };
    // },
    // getMarkersL() {
    //   let t=this
    //   t.$http.post(localStorage.getItem('s')+'/app/getMarkers/').then(function (response) {
    //     let markersList = []
    //     let markers = L.markerClusterGroup({});//создаем группу кластеризации
    //     for (let i = 0; i < response.data.length; i++) {
    //       let icon = L.icon({
    //         iconUrl:localStorage.getItem('s')+response.data[i].image,
    //         iconSize:[30,30],
    //         className:'icon',
    //       });
    //       let user = response.data[i].nick;
    //       let page = response.data[i].page;
    //       let status = response.data[i].status;
    //       let info = status?status:user;
    //       //  let info = String(document.write('<br>'))
    //       let marker = L.marker([response.data[i].latitude, response.data[i].longitude], {icon:icon});
    //       marker.bindTooltip(info).openTooltip();// окошко имя юзера
    //       marker.bindPopup(user)
    //       marker.on('click', onClick.bind(this).bind(user));
    //       function onClick(e){
    //         if (user!=this.me){
    //           if (localStorage.getItem("openHot")=="0"){
    //             localStorage.setItem("user", user);
    //             this.$emit('profile')
    //           }else{
    //             if (page===""){
    //               localStorage.setItem("user", user);
    //               this.$emit('profile')
    //             }else{
    //               if (!page.match(/^https?:\/\//i)) {
    //                 page = 'http://' + page;
    //               }
    //               window.open(page, '_blank');
    //             }
    //           }
    //         }
    //         else {
    //           this.$emit('myprofile')
    //         }
    //       }
    //       markers.addLayer(marker);//добавляем маркеры в группу
    //       markersList.push(marker)
    //     }
    //
    //     t.socket.onmessage = function(event) {
    //       let msg = JSON.parse(event.data);
    //       console.log(msg)
    //       for (let i = 0; i < markersList.length; i++) {
    //         let m=markersList[i]
    //         console.log(m.getPopup().getContent())
    //         if (m.getPopup().getContent() === msg.me){
    //           console.log('ok')
    //           m.animate({
    //             latlngs: [msg.lat,msg.lng],
    //             duration: 1,
    //             easing: 'ease-in-out'
    //           });
    //         }
    //       }
    //       // if (msg.me === t.me && t.mypos){
    //       //   let currentZoom = t.map.getZoom();
    //       //   t.map.setView([msg.lat, msg.lng], currentZoom);
    //       // }
    //     }
    //     t.map.addLayer(markers);
    //   })
    // },
    // initMagazine(e){
    //   localStorage.setItem('magazineLat', e.latlng.lat)
    //   localStorage.setItem('magazineLng', e.latlng.lng)
    //   this.$emit('magazine')
    // }
  },
  // beforeDestroy() {
  //     this.socket.close()
  // },
}
</script>

<style scoped="">
@import url('../../public/assets/leaflet/prunecluster/LeafletStyleSheet.css');

#map {
  /*height: 94.3%;*/
  height: calc(100% - 51px);
  /*height: 100%;*/
  width: 100vw;
}
div#size, a#delete {
  position: absolute;
  right: 1em;
  top: 1em;
  background: white;
  color: black;
  padding: 0.4em;
  border-radius: 4px;
  z-index: 500;
}
</style>
