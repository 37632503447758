import methods from "./methods";
import api from "./api";

const tg = {
  async signInTelegram() {
    const phoneDiv = document.getElementById("phoneDiv");
    const phone = document.getElementById("phone");
    const phoneSend = document.getElementById("phoneSend");
    const codeDiv = document.getElementById("codeDiv");
    const code = document.getElementById("code");
    const codeSend = document.getElementById("codeSend");
    const passDiv = document.getElementById("passDiv");
    const pass = document.getElementById("pass");
    const passSend = document.getElementById("passSend");
    function phoneCallback() {
      phoneDiv.style.visibility = "visible";
      return new Promise((resolve) => {
        phoneSend.addEventListener("click", function () {
          phone.disabled = true;
          phoneSend.disabled = true;
          resolve(phone.value);
        });
      });
    }
    function passwordCallback() {
      passDiv.style.visibility = "visible";
      return new Promise((resolve) => {
        passSend.addEventListener("click", function () {
          code.disabled = true;
          codeSend.disabled = true;
          resolve(pass.value);
          alert("welcome");
        });
      });
    }
    function codeCallback() {
      code.disabled = false;
      codeSend.disabled = false;
      codeDiv.style.visibility = "visible";
      return new Promise((resolve) => {
        codeSend.addEventListener("click", function () {
          code.disabled = true;
          codeSend.disabled = true;
          resolve(code.value);
        });
      });
    }
    let user = await tg.getMe();
    console.log(user)
    //const my_phone = '+375255451660';
    const my_phone = await phoneCallback()
    if (!user) {
      const {phone_code_hash} = await methods.sendCode(my_phone);
      const code = await codeCallback()
      try {
        const signInResult = await methods.signIn({my_phone, code, phone_code_hash});
        if (signInResult._ === 'auth.authorizationSignUpRequired') {
          console.log("singUp")
          await methods.signUp({my_phone, phone_code_hash});
        }
      } catch (error) {
        if (error.error_message !== 'SESSION_PASSWORD_NEEDED') {
          console.log(error);
          return;
        }
        // 2FA
        // const password = '1371';
        console.log('1')
        const password = await passwordCallback()
        const {srp_id, current_algo, srp_B} = await methods.getPassword();
        const {g, p, salt1, salt2} = current_algo;
        const {A, M1} = await api.crypto.getSRPParams({g, p, salt1, salt2, gB: srp_B, password});
        const checkPasswordResult = await methods.checkPassword({srp_id, A, M1});
      }
    } else {
      console.log('logged')
    }
  },
  async sendPhotosGroup(files, groupId, caption) {
    const res = await tg.getDialogs();
    const all_groups = res.chats.filter(chat => chat._ !== 'chatForbidden');
    const group = all_groups.find(chat => chat.id === groupId);

    if (group !== null) {
      const mediaList = [];

      for (let file of files) {
        if (file.type.startsWith('image/')) {
          if (file.size > 512 * 1024) {
            file = await methods.compressImage(file, 512 * 1024);
          }
        }

        const bytes = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(new Uint8Array(event.target.result));
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });

        try {
          // Сначала загружаем медиафайл с использованием upload.saveFilePart и uploadMedia
          const fileId = Math.floor(Math.random() * 0xFFFFFFFF);
          await api.call('upload.saveFilePart', {
            file_id: fileId,
            file_part: 0,
            bytes: bytes,
          });

          // Загружаем медиа через messages.uploadMedia
          const uploadedMedia = await api.call('messages.uploadMedia', {
            peer: { _: 'inputPeerSelf' },
            media: { _: 'inputMediaUploadedPhoto', file: { _: 'inputFile', id: fileId, parts: 1 } }
          });

          console.log(uploadedMedia)

          // Добавляем загруженное медиа в список для отправки с sendMultiMedia
          mediaList.push({
            _: 'inputSingleMedia',
            media: { _: 'inputMediaPhoto', id: uploadedMedia.photo.id },
            random_id: Math.floor(Math.random() * 1000000)
          });

          // await api.call('messages.sendMedia', {
          //   peer: { _: 'inputPeerChat', chat_id: group.id },
          //   media: {
          //     _: 'inputSingleMedia',
          //     media: { _: 'inputMediaPhoto', id: uploadedMedia.photo.id },
          //     random_id: Math.floor(Math.random() * 1000000)
          //   },
          //   message: caption,
          //   random_id: Math.floor(Math.random() * 1000000),
          // });



          if (mediaList.length === 10) {
            await methods.sendMultiMedia(group, mediaList, caption);
            mediaList.length = 0; // Очищаем массив для следующего пакета
          }
        } catch (e) {
          console.error('Ошибка при отправке фото:', e);
        }
      }

      //Отправляем оставшиеся медиафайлы, если они есть
      if (mediaList.length > 0) {
        await methods.sendMultiMedia(group, mediaList, caption);
      }

      return mediaList
    }
  },
  async sendPhotos(files, groupId, caption) {
    const res = await tg.getDialogs();
    const all_groups = res.chats.filter(chat => chat._ !== 'chatForbidden');
    const group = all_groups.find(chat => chat.id === groupId);
    // console.log(group);

    if (group !== null) {
      // Перебираем файлы с использованием for...of и await, чтобы последовательно обрабатывать каждый файл
      let photos = []
      for (let file of files) {
        if (file.type.startsWith('image/')) {
          // Проверяем, превышает ли размер файла 512 КБ
          if (file.size > 512 * 1024) {
            // Если да, сжимаем изображение
            file = await methods.compressImage(file, 512 * 1024);
          }
        }

        // Создаем новый FileReader и обрабатываем его с использованием промисов
        const bytes = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(new Uint8Array(event.target.result));
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });

        try {
          // Загружаем файл в Telegram
          const result = await api.call('upload.saveFilePart', {
            file: {
              _: 'inputFile',
              id: 0, // ID файла
              parts: 1, // Часть файла
            },
            bytes: bytes,
          });
          console.log('Фото отправлено:', result);
        } catch (e) {
          console.error('Ошибка при отправке фото:', e);
        }
        const res = await methods.sendMedia(group, file, caption);
        photos.push({id:res.updates[1].message.media.photo.id,date:res.date})
        // photos.push(res)
      }
      return photos
    }
  },
  sendMessage(user_id, access_hash, message){
    console.log(user_id)
    return api.call('messages.sendMessage',{
      peer:{_: 'inputPeerUser', user_id: user_id, access_hash:access_hash},
      message:message,
      random_id: Math.floor(Math.random() * 1000000)
    })
  },
  getAdminedPublicChannels(){
    return api.call('channels.getAdminedPublicChannels',{
      user_id: {_: 'inputUserSelf'},
      offset_peer: {_: 'inputUserSelf'},
    })
  },
  getDialogs(){
    return api.call('messages.getDialogs',{
      limit: 1000,  // Количество диалогов, которые вы хотите получить
      offset_date: 0,  // Фильтр по дате
      offset_id: 0,  // Фильтр по ID сообщения
      offset_peer: { _: 'inputPeerEmpty' },  // Фильтр по пиру
      hash: 0  // Хэш для обновления списка диалогов
    })
  },
  logOut(){
    return api.call('auth.logOut')
  },
  async getMe(){
    const user = await methods.getFullUser()
    if(user) return user.users[0]
    else return null
  },
  async getUser(username){
    const user = await methods.resolveUsername(username)
    if (user!==null)
      return user.users[0]
    else
      return undefined
  },
  async getUserPhoto(user){
    if(user!==undefined && user.photo!==undefined && user.access_hash!==undefined){
      const peer = { _: "inputPeerUser", user_id: user.id, access_hash: user.access_hash };
      const params = {
        location: {_: "inputPeerPhotoFileLocation", big: false, peer: peer, photo_id: user.photo.photo_id},
        limit: 512 * 512, // 1024x1024 etc
        offset: 0,
      }
      const res = await methods.getFile(params);
      // console.log(res)
      if(res!==null){
        const uint8ArrayData = res.bytes
        const blob = new Blob([uint8ArrayData], { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob)
        return url;
      }else{
        return localStorage.s+'/images/default.jpg'
      }
    }else{
      return localStorage.s+'/images/default.jpg'
    }

  },
  async getChannelPhoto(channel){
    if(channel.photo.photo_id!==undefined && channel.access_hash!==undefined){
      const peer = { _: "inputPeerChannel", channel_id: channel.id, access_hash: channel.access_hash };
      const params = {
        location: {_: "inputPeerPhotoFileLocation", big: false, peer: peer, photo_id: channel.photo.photo_id},
        limit: 512 * 512, // 1024x1024 etc
        offset: 0,
      }
      const res = await methods.getFile(params);
      if(res!==null){
        const uint8ArrayData = res.bytes;
        const blob = new Blob([uint8ArrayData], { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        // console.log(url)
        return url;
      }else{
        return localStorage.s+'/images/default.jpg'
      }
    }else{
      return localStorage.s+'/images/default.jpg'
    }

  },
  async getPhotoByMesId(id){
    const mes = await methods.getMessages(id)
    const photo = mes.messages[0].media.photo
    return await tg.getPhotoUrl(photo);
  },
  async getPhotosByDateAndId(photos){
    console.log(photos)
    const dates = photos.map(photo=>photo.date)
    console.log(dates)

    const maxDate = Math.max(...dates);
    console.log(maxDate)

    let res = await methods.getHistoryByDate(localStorage.group_map_id, maxDate)
    console.log(res)
    // const urls = []
    // for(const item of photos){
    //   console.log(res)
    //   const message = res.messages.filter(message=>message.media.photo.id === item.id)
    //   const photo = message.media.photo
    //
    //   const params = {
    //     location: {_: "inputPhotoFileLocation", id: photo.id, access_hash: photo.access_hash, file_reference:photo.file_reference,thumb_size:photo.sizes.find(size => size._ == 'photoStrippedSize').type},
    //     limit: 512 * 512, // 1024x1024 etc
    //     offset: 0,
    //   }
    //   const res = await methods.getFile(params);
    //   if(res!==null){
    //     const uint8ArrayData = res.bytes;
    //     const blob = new Blob([uint8ArrayData], { type: 'image/jpeg' });
    //     const url = URL.createObjectURL(blob);
    //     // console.log(url)
    //     urls.push(url);
    //   }else{
    //     urls.push(localStorage.s+'/images/default.jpg')
    //   }
    // }
    // return urls


    const promises = photos.map(async (item) => {
      console.log("Обрабатываем фото:", item);

      // Поиск сообщения с фотографией
      const message = res.messages.find(message => {
        try{
          return message.media.photo.id === item.id;
        }catch{
          return null
        }
      });

      if (message && message.media && message.media.photo) {
        const photo = message.media.photo;
        console.log("Фото найдено:", photo);
        return {url:await tg.getPhotoUrl(photo),text:message.message}
      } else {
        console.error("Сообщение с нужной фотографией не найдено для item:", item);
        return null;
      }
    });

    // Дожидаемся выполнения всех промисов
    const urls = await Promise.all(promises);
    console.log("URLs фотографий:", urls);
    return urls;
  },
  async getPhotoUrl(photo) {
    try {
      const params = {
        location: {
          _: "inputPhotoFileLocation",
          id: photo.id,
          access_hash: photo.access_hash,
          file_reference: photo.file_reference,
          thumb_size: photo.sizes.find(size => size._ == 'photoStrippedSize').type,
        },
        limit: 1024 * 1024,  // Максимальный размер загрузки
        offset: 0            // С начала файла
      };

      const res = await api.call("upload.getFile", params);

      if (res && res.bytes) {
        const uint8ArrayData = res.bytes;
        const blob = new Blob([uint8ArrayData], { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        return url;
      } else {
        console.error("Не удалось получить файл для фото:", photo.id);
        return null;
      }
    } catch (error) {
      console.error("Ошибка при получении файла для фото:", error);
      return null;
    }
  },

}

export default tg;

