<script>
import OverlayPanel from 'primevue/overlaypanel';
import tg from "../telegram/tg";
export default {
    components:{OverlayPanel},
  data(){
    return{
      icon:localStorage.getItem('mypos')==='true'?'✔':'❌',
      openHot:localStorage.getItem('openHot')==='1'?'✔':'❌',
      active_map:localStorage.state==='map'?'red':'none',
      active_WS:localStorage.state==='WS'?'red':'none',
      active_tg:localStorage.state==='tg'?'red':'none',
    }
  },
  mounted(){
    this.myServer();
    // console.log(localStorage.state)
  },
  methods:{
    settings_toggle(e){this.$refs.settings.toggle(e)},
    language_toggle(e){this.$refs.language.toggle(e)},
    switchLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.mapa_locale = locale
    },
    myServer(){
      // localStorage.s = 'http://127.0.0.1:8008'
      // localStorage.socket = 'ws://127.0.0.1:8008/ws/'
      localStorage.s = 'https://mapa.ws/api'
      localStorage.socket = 'wss://mapa.ws/api/ws/'
    },
    logout(){
      if(localStorage.login === 'site'){
        this.$http.post(localStorage.getItem("s")+'/app/logout/', {me:localStorage.getItem('me')})
      }else if(localStorage.login === 'tg'){
        tg.logOut()
      }
      localStorage.removeItem("me");
      localStorage.removeItem("state");
      localStorage.state = 'map'
      localStorage.removeItem("zoom");
      localStorage.removeItem("lat");
      localStorage.removeItem("lng");
      localStorage.removeItem("mypos");
      localStorage.removeItem("login")
      document.location.reload(true);
    },
    // map(){
    //   let state=localStorage.getItem("state")
    //   this.$emit(state)
    //   // this.$emit('map')
    // },
    CreateTest(){
      this.$http.post('http://localhost:8000/app/test/', {data:'create'})
    },
    DeleteTest(){
      this.$http.post('http://localhost:8000/app/test/', {data:'delete'})
    },
    myPos(){
      let x=localStorage.getItem("mypos");
      if (x==='false') {
        localStorage.setItem("mypos", true)
        this.icon='✔'
        document.location.reload(true);
      }
      if (x==='true') {
        localStorage.setItem("mypos", false)
        this.icon='❌'
        document.location.reload(true);
      }
    },
    upOpenHot(){
      if(localStorage.getItem('openHot')==='0'){
        localStorage.setItem("openHot", '1');
        this.openHot='✔'
      }else{
        localStorage.setItem("openHot", '0');
        this.openHot='❌'
      }
    },
    SwitchState(state){
      if(state=='WS'){
        this.active_WS='red'
        this.active_map='#333'
        this.active_tg='#333'
      } else if(state=='map'){
        this.active_map='red'
        this.active_WS='#333'
        this.active_tg='#333'
      } else if(state=='tg'){
        this.active_tg='red'
        this.active_WS='#333'
        this.active_map='#333'
      }
      this.$emit(state)
    },
  },
  computed:{
      state(){
        return localStorage.state
      },
    me(){
      return localStorage.getItem("me")
    },
    //значение true когда пользователь авторизован
     meen(){
      if (localStorage.getItem("me")!=undefined){return true}
      if (localStorage.getItem("me")==undefined){return false}
    },
    //значение true когда пользователь не авторизован
    muun(){
      if (localStorage.getItem("me")!=undefined){return false}
      if (localStorage.getItem("me")==undefined){return true}
    },
  }
}
</script>
<template>
  <div class="navbar">
    <div class="navbar-container">
        <a class="" href="">
          <img class="logo" src="/assets/logo/logo.png">
        </a>
        <div class="navbar-content">
          <a class="navlinks" v-if="muun" @click="$emit('auth','login')">{{ $t('auth.login') }}</a>
<!--          <a class="navlinks" v-if="muun" @click="$emit('auth','reg')">{{$t('auth.reg')}}</a>-->
          <a class="navlinks" v-if="meen" @click="$emit(state)"><img class="nav_item" src="/assets/icons/white/map.png"></a>
          <a class="navlinks" v-if="meen" @click="$emit('groups')"><img src="/assets/icons/white/sms.png"></a>
          <a class="navlinks" v-if="meen" @click="$emit('friends')"><img src="/assets/icons/white/friends.png"></a>
          <a class="navlinks" v-if="meen" href=""><img src="/assets/icons/white/reload.png"></a>
          <a aria-controls="overlay_menu" aria-haspopup="true" class="navlinks" @click="settings_toggle" v-if="meen">
            <img class="img" src="/assets/icons/white/options.png">
          </a>
          <OverlayPanel ref="settings" appendTo="body" class="settings_menu">
              <p class="select" @click="$emit('myprofile')"><b>{{me}}</b></p>
              <p class="select" @click="myPos()"><img src="/assets/icons/white/position_black.png">{{icon}}</p>
              <p class="select" @click="upOpenHot()"><img src="/assets/icons/white/redirect_to_site.png">{{openHot}}</p>
              <p class="select" @click="logout()"><img src="/assets/icons/white/exit.png"></p>
<!--              <p class="select" @click="SwitchState('map')" :style="{color:active_map}">map</p>-->
<!--              <p class="select" @click="SwitchState('WS')" :style="{color:active_WS}">realtime map</p>-->
<!--              <p class="select" @click="SwitchState('tg')" :style="{color:active_tg}">tg map</p>-->
<!--              <li class="divider"></li>-->
<!--              <li><a class="select" @click="$emit('Bluetooth')">Bluetooth</a></li>-->
<!--              <li class="divider"></li>-->
<!--              <li><a class="select" v-if="meen" @click="$emit('reg')">Регистрация</a></li>-->
<!--              <li class="divider"></li>-->
<!--              <li><a class="select" @click="CreateTest">Create Test</a></li>-->
<!--              <li class="divider"></li>-->
<!--              <li><a class="select" @click="DeleteTest">Delete Test</a></li>-->
<!--              <li class="divider"></li>-->
<!--            </ul>-->
          </OverlayPanel>
          <a class="navlinks" style="text-transform: uppercase" @click="language_toggle">{{$i18n.locale}}</a>
          <OverlayPanel ref="language" class="settings_menu">
              <p class="select" @click="switchLocale('ru')"><b>Русский</b></p>
              <p class="select" @click="switchLocale('en')"><b>English</b></p>
          </OverlayPanel>
        </div>
      </div>
    </div>
</template>
<style scoped>
@media (max-width: 400px) {
  .navbar-brand {
    display: none;
  }
}
@media (max-width: 768px) {
  .navbar-right {
        float: right !important;
        margin-right: -15px;
  }
}
.navbar{
  position: relative;
  height: 50px;
  border: none;
  background: #2C608C;
}
.navbar-container{
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: stretch;
}
.navbar-content{
  display: flex;
  align-items: stretch;
}
.navlinks{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  text-decoration: none!important;
  caret-color: transparent;
}
.navlinks:hover{
 background: #4a76a8;
 cursor: pointer;
}
.nav_item{
  margin: auto 0;
}

a{
  color: white;/*!!!*/
}

@media (max-width: 500px) {
  .logo {
    display: none;
  }
}
</style>
