<template>
  <div>
    <div id="map"></div>
    <div id="context-menu" class="context-menu"></div>

<!--    <div v-if="showMenu" class="context-menu" :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }">-->
<!--      <ul>-->
<!--        <li class="context-menu-item" @click="deleteStaticMarker">Удалить маркер</li>-->
<!--      </ul>-->
<!--    </div>-->

    <Dialog header="Создать маркер" :visible.sync="create_marker" :modal="true" close-on-escape>
      <label>Название</label>
      <div class="field">
        <InputText  :class="{'p-invalid': v$.marker_title.$invalid && sub}" v-model="v$.marker_title.$model"/>
      </div>
      <label>Широта</label>
      <div class="field">
        <InputText :class="{'p-invalid': v$.marker_lat.$invalid && sub}" v-model="v$.marker_lat.$model"/>
      </div>
      <label>Долгота</label>
      <div class="field">
        <InputText :class="{'p-invalid': v$.marker_lng.$invalid && sub}" v-model="v$.marker_lng.$model"/>
      </div>

      <div v-if="files!==null">
        <label>Текст</label>
        <div class="field">
          <Textarea rows="5" cols="30"  :class="{'p-invalid': v$.marker_text.$invalid && sub}" v-model="v$.marker_text.$model"/>
        </div>
      </div>


      <div class="field">
        <file-upload @getFiles="getFiles" v-model:invalid="v$.files.$invalid && sub"/>
      </div>

      <template #footer>
        <Button label="Закрыть" autofocus @click="create_marker=false"/>
        <Button label="ОК" autofocus @click="sendStaticMarker"/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { PruneCluster, PruneClusterForLeaflet } from 'exports-loader?PruneCluster,PruneClusterForLeaflet!prunecluster/dist/PruneCluster.js'
import tg from "../telegram/tg";
import socket from "../socket";
import {TgMapStore} from "../store";
import Dialog from "primevue/dialog";
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import OverlayPanel from "primevue/overlaypanel";
import fileUpload from "../components/fileUpload.vue";
import Textarea from 'primevue/textarea';
// import "../../public/assets/code"
// import '../../public/assets/leaflet/leaflet.css'
// import '../../public/assets/leaflet/leaflet.js'
// import '../../public/assets/leaflet/esri/esri-leaflet'
// import '../../public/assets/leaflet/esri-geocoder/esri-leaflet-geocoder.css'
// import '../../public/assets/leaflet/esri-geocoder/esri-leaflet-geocoder'

import "../../public/assets/leaflet/draw/getdraw"
export default {
  components:{Dialog,OverlayPanel,fileUpload,Textarea},
  setup() {
    const tgMapStore = TgMapStore()
    return {tgMapStore,v$:useVuelidate()}
  },
  data(){
    return {
      me:localStorage.getItem("me"),
      map:null,
      tileLayer:null,
      mypos: localStorage.getItem("mypos")!==undefined ? JSON.parse(localStorage.getItem("mypos")) : true,
      lat:localStorage.getItem("lat")!==undefined ? localStorage.getItem("lat") : 53.9045398,
      lng:localStorage.getItem("lng")!==undefined ? localStorage.getItem("lng") : 27.5615244,
      zoom:localStorage.getItem("zoom")!==undefined ? localStorage.getItem("zoom") : 10,
      // myLat:null,
      // myLng:null,
      duration: 2000, // Длительность анимации в миллисекундах
      startTime: null,
      leafletView: null,
      markers:[],
      room:localStorage.group_map_id!==undefined ? localStorage.group_map_id:null,
      create_marker:false,
      marker_title:null,
      marker_lat:null,
      marker_lng:null,
      marker_text:null,
      sub:false,

      marker_id:null,
      showMenu: false,
      menuPosition: { x: 0, y: 0 },

      files:null,
    }
  },
  validations(){
    return{
      marker_title:{required, maxLength: maxLength(30)},
      marker_lat:{required},
      marker_lng:{required},
      marker_text:{},
      files:{max_col(){
        try{
          return this.files.length<=30
        }catch{
          return this.files === null
        }

      }}
    }
  },
  created() {
    socket.onopen = function() {console.log("Соединение установлено")};
  },
  mounted() {
    // console.log('tg')
    console.log('room: '+this.room)
    this.initMap()
    this.initMoov()
    setTimeout(function(){this.initLocation()}.bind(this),2000)
    setTimeout(function(){this.getMarkers()}.bind(this),2000)
    setTimeout(function(){
      // console.log('watch')
      this.map.stopLocate()
      this.map.locate({setView: localStorage.getItem('getpos')===undefined?this.mypos:false, maxZoom: localStorage.getItem("zoom"), watch:true, enableHighAccuracy:true})
    }.bind(this),3000)


    setTimeout(function(){
      if(localStorage.group_map_id!==undefined){
        this.getStaticMarkers()
      }
    }.bind(this),4000)

    // setTimeout(function(){
    //   if(this.tgMapStore.online === false){
    //     socket.send(JSON.stringify({me:this.me, lat:'', lng:''}))
    //     this.tgMapStore.toggleOnline()
    //   }
    // }.bind(this),1000);

    // if(this.me === 'atkisai' && !this.tgMapStore.online)
    //   setTimeout(function(){this.pushFakeUsers()}.bind(this),4000);

    // setTimeout(function(){
    //   setInterval(this.markerMovementIllusion, 3000);
    // }.bind(this),4000);

    // this.tgMapStore.toggleOnline()
  },
  methods: {
    getFiles(files){
      console.log(files)
      this.files=files
    },
    getStaticMarkers(){
      this.map.on('dblclick', this.setMarker)
      this.$http.post(localStorage.s+'/tg/markers/', {group_id:localStorage.group_map_id, get_markers:"OK"}).then((res) => {
        console.log(res)
        if (res.ok === true && res.data!==null) {
          for(let user of res.data){
            this.createMarker(user)
          }
          // if(this.tgMapStore.users.length===0){
          //   for(let user of res.data){
          //     this.createMarker(user)
          //   }
          // }else{
          //   for(let user of res.data){
          //     // const res = this.tgMapStore.users.filter(item=>item.me===user.me)
          //     if(this.tgMapStore.users.filter(item=>item.me===user.me).length===0){
          //       this.createMarker(user)
          //       this.tgMapStore.addUser(user)
          //     }
          //   }
          // }
        }
      });
    },
    deleteStaticMarker(marker){
      console.log(marker)
    },
    async sendStaticMarker(){
      const t = this

      // if(t.files!==null && t.room!==null){
      //   tg.sendPhotos(t.files, t.room)
      // }

      t.sub=true
      if(!t.v$.marker_title.$invalid && !t.v$.marker_lat.$invalid && !t.v$.marker_lng.$invalid && !t.v$.files.$invalid){

        let photos
        if(t.marker_text===null)
          t.marker_text="Здесь могла быть ваша реклама!"
        if(t.files!==null){
          photos = await tg.sendPhotos(t.files, t.room, t.marker_text)
          console.log(photos)
        }else{
          photos = []
        }

        const data = {
          // room: t.room,
          lat: t.marker_lat,
          lng: t.marker_lng,
          me: t.marker_title,
          marker: true,
          photos:photos,
        }

        socket.send(JSON.stringify(data))
        t.create_marker=false


        this.$http.post(localStorage.s+'/tg/markers/', {
          me: t.me,
          title:t.marker_title,
          lat: t.marker_lat,
          lng: t.marker_lng,
          group_id:localStorage.group_map_id,
          add_marker:"OK",
          items: photos,

        }).then((res) => {
          console.log(res)


        });
      }
    },
    initMoov(){
      this.map.on("moveend", this.getMoov);
    },
    getMoov(e){
      let zoom = this.map.getZoom();
      localStorage.setItem("zoom", zoom);
      let center = this.map.getCenter();
      localStorage.setItem("lat", center.lat);
      localStorage.setItem("lng", center.lng);
    },
    markerMovementIllusion(){
      this.myLat+=0.0001
      const data = {
        lat: this.myLat,
        lng: this.myLng,
        me: this.me,
      };
      socket.send(JSON.stringify(data))
    },
    pushFakeUsers(){
      function random(latOrLng) {
        const lat = 54.2279000;
        const lng = 28.5050000;
        if (latOrLng === "lat") {
          return (lat - 1) + Math.random() * ((lat + 1) - (lat - 1));
        } else {
          return (lng - 1) + Math.random() * ((lng + 1) - (lng - 1));
        }
      }

      const users = []
      for (let i = 0; i<100;i++){
        users.push(
          {me:'fake-user-'+i,lat:random('lat'),lng:random('lng')}
        )
      }

      console.log(users)

      function getRandomUser(users) {
        const randomIndex = Math.floor(Math.random() * users.length);  // Генерируем случайный индекс
        return users[randomIndex];  // Возвращаем элемент списка по случайному индексу
      }

      function pushUser(){
        const user = getRandomUser(users)
        // console.log(user)
        socket.send(JSON.stringify(user))
      }

      setTimeout(function(){
        setInterval(pushUser, 1000);
      },5000);
    },
    initMap() {
      if(this.lat === null || this.lng ===  null){
        this.lat = 53.9045398
        this.lng = 27.5615244
      }
      const osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
      const osmAttrib = '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors';
      const osm = L.tileLayer(osmUrl, { maxZoom: 18, attribution: osmAttrib });
      this.map = L.map ('map'). setView ([this.lat,this.lng], localStorage.getItem("zoom"));
      this.map.addLayer(osm);
      const drawnItems = L.featureGroup().addTo(this.map);
      this.map.on('dblclick', this.setMarker)
      L.control.layers({
        'osm': osm.addTo(this.map),
        "google": L.tileLayer('http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}', {
          attribution: 'google'
        })
      }, { 'drawlayer': drawnItems }, { position: 'topleft', collapsed: false }).addTo(this.map);
      this.map.addControl(new L.Control.Draw({
        edit: {
          featureGroup: drawnItems,
          poly: {
            allowIntersection: false
          }
        },
        draw: {
          polygon: {
            allowIntersection: false,
            showArea: true
          }
        }
      }));
      this.map.on(L.Draw.Event.CREATED, function (event) {
        const layer = event.layer;
        drawnItems.addLayer(layer);
      });
      // this.map = L.map ('map'). setView ([this.lat,this.lng], localStorage.getItem("zoom"));//[53.9045398,27.5615244]
      // this.tileLayer = L.tileLayer ('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
      //     maxZoom: 19,
      //     attribution: '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      //   }
      // );
      // this.tileLayer.addTo(this.map);
      // drawnItems = L.featureGroup().addTo(map);
    },
    initLocation() {
      this.map.locate({setView: localStorage.getItem('getpos')===undefined?this.mypos:false, maxZoom: localStorage.getItem("zoom"), watch:false, enableHighAccuracy:true});//watch:true - fo real time, setView:true - see locate, maxZoom: this.zoom
      if(this.room===null)
        this.map.on('locationfound', this.onLocationFound);
      else
        this.map.on('locationfound', this.onLocationFoundRoom);

    },
    onLocationFound(e){
      // this.myLat=e.latlng.lat
      // this.myLng=e.latlng.lng
      const data = {
        lat: e.latlng.lat,
        lng: e.latlng.lng,
        me: this.me,
      }
      socket.send(JSON.stringify(data))
    },
    onLocationFoundRoom(e){
      // this.myLat=e.latlng.lat
      // this.myLng=e.latlng.lng
      const data = {
        room: this.room,
        lat: e.latlng.lat,
        lng: e.latlng.lng,
        me: this.me,
      }
      socket.send(JSON.stringify(data))
    },
    async animateMarkers(markers, msg) {
      const t = this
      let promises = markers.map(async m => {
        if (m.data.user === msg.me) {
          t.tgMapStore.updateUser(msg)
          let currentLatLng = [m.position.lat, m.position.lng];
          let targetLatLng = [msg.lat, msg.lng];
          if (msg.me === t.me && t.mypos) {
            requestAnimationFrame(t.animateMarkerWrapper(m, null, currentLatLng, targetLatLng));
          } else {
            requestAnimationFrame(t.animateMarkerWrapperNoCamera(m, null, currentLatLng, targetLatLng));
          }
          return true;
        }
        return false;
      });
      let results = await Promise.all(promises);
      // Проверяем, все ли результаты равны false
      if (!results.some(result => result === true)) {
        await t.createMarker(msg)
        t.tgMapStore.addUser(msg)
      }
    },
    getMarkers() {
      const t = this
      t.leafletView = new PruneClusterForLeaflet()
      console.log(t.tgMapStore.users)
      for(let user of t.tgMapStore.users){
        // if(user.marker===false)
          t.createMarker(user)
        // else
        //   t.createStaticMarker(user)
      }

      socket.onmessage = function (event) {
        let msg = JSON.parse(event.data);
        // console.log(msg)
        if(msg.marker==false){
          t.animateMarkers(t.markers, msg);
        } else{
          // t.createStaticMarker(msg)
          t.createMarker(msg)
          // t.tgMapStore.addUser(msg)
        }
      };
      t.map.addLayer(t.leafletView);
    },
    async createMarker(user){
      // console.log(user)
      const t = this
      let marker = new PruneCluster.Marker(user.lat, user.lng);
      marker.data.info = user.me;
      marker.data.user = user.me;
      let url = ''
      if(user.me.includes("fake-user")){
        url = localStorage.s+'/images/default.jpg'
        marker.data.icon = L.icon({
          iconUrl: url,
          iconSize: [40, 40],
        });
      }else if(user.marker===true || user.group_id!==undefined){
        marker.data.marker = true
        marker.data.id = user.id
        marker.data.owner = user.profile_name
        marker.data.photos = user.photos
        url = "https://mapa.ws/marker-icon.png"
        marker.data.icon = L.icon({
          iconUrl: url,
          iconSize: [30,53],
        });
      }else {
        url = await tg.getUserPhoto(await tg.getUser(user.me))
        marker.data.icon = L.icon({
          iconUrl: url,
          iconSize: [40, 40],
        });
      }

      // marker.data.icon = L.icon({
      //   iconUrl: url,
      //   // iconSize: [30, 30],
      // });

      t.leafletView.PrepareLeafletMarker = function (leafletMarker, marker) {
        // console.log(marker)
        leafletMarker.setIcon(marker.icon);
        leafletMarker.bindTooltip(marker.info);
        leafletMarker.bindPopup(marker.info);

        if(marker.marker){
          const contextMenu = document.getElementById('context-menu');
          leafletMarker.off('contextmenu')
          leafletMarker.on('contextmenu', function (e) {
            // console.log(event)
            // this.showMenu = true; // Показываем меню
            // this.menuPosition = { x: e.originalEvent.pageX, y: e.originalEvent.pageY }; // Устанавливаем позицию меню
            contextMenu.style.display = 'block';
            contextMenu.style.left = e.originalEvent.clientX + 'px';
            contextMenu.style.top = e.originalEvent.clientY + 'px';
            contextMenu.dataset.lat = e.target.getLatLng().lat;
            contextMenu.dataset.lng = e.target.getLatLng().lng;
          });
          window.addEventListener('click', function() {
            contextMenu.style.display = 'none';
            // this.showMenu = false;
          });
          contextMenu.innerHTML = "<div class=\"context-menu-item\" onclick=\"this.deleteStaticMarker('lol')\">Удалить маркер</div>\n"
        }

        leafletMarker.off('click')
        leafletMarker.on('click', function () {
          if (marker.user !== t.me) {
            if(marker.marker){
              t.tgMapStore.addMarker(marker)
              t.$emit('marker');
            }else if (localStorage.getItem("openHot") === "0") {
              localStorage.setItem("user", marker.user);
              t.$emit('profile');
            }
          } else {
            t.$emit('myprofile');
          }
        });
      }.bind(this)

      t.markers.push(marker);
      // console.log(marker)
      t.leafletView.RegisterMarker(marker);
      // if(marker.data.user === t.me){
      //   t.clickAnimation(marker)
      // }
      t.leafletView.ProcessView();
    },
    setMarker(e){
      // localStorage.setItem('magazineLat', e.latlng.lat)
      // localStorage.setItem('magazineLng', e.latlng.lng)
      // this.$emit('magazine')
      // L.marker([e.latlng.lat, e.latlng.lng]).addTo(this.map).bindPopup('').openPopup();
      this.create_marker=true
      this.marker_lat=e.latlng.lat
      this.marker_lng=e.latlng.lng
    },
    animateMarkerWrapper(marker, startTime, currentLatLng, targetLatLng) {
      let t = this;
      return function (timestamp) {
        if (!startTime) {
          startTime = timestamp;
        }
        let progressFraction = (timestamp - startTime)/t.duration
        if (progressFraction < 1) {
          let interpolatedLatLng = [
            currentLatLng[0] + (targetLatLng[0] - currentLatLng[0]) * progressFraction,
            currentLatLng[1] + (targetLatLng[1] - currentLatLng[1]) * progressFraction
          ];
          marker.position.lat = interpolatedLatLng[0];
          marker.position.lng = interpolatedLatLng[1];
          t.map.setView([interpolatedLatLng[0], interpolatedLatLng[1]], t.map.getZoom());
          t.leafletView.ProcessView();
          requestAnimationFrame(t.animateMarkerWrapper(marker, startTime, currentLatLng, targetLatLng));
        }
      };
    },
    animateMarkerWrapperNoCamera(marker, startTime, currentLatLng, targetLatLng) {
      let t = this;
      return function (timestamp) {
        if (!startTime) {
          startTime = timestamp;
        }
        let progressFraction = (timestamp - startTime)/t.duration
        if (progressFraction < 1) {
          marker.position.lat = currentLatLng[0] + (targetLatLng[0] - currentLatLng[0]) * progressFraction;
          marker.position.lng = currentLatLng[1] + (targetLatLng[1] - currentLatLng[1]) * progressFraction;
          t.leafletView.ProcessView();
          requestAnimationFrame(t.animateMarkerWrapperNoCamera(marker, startTime, currentLatLng, targetLatLng));
        }
      };
    },
    clickAnimation(m){
      let t = this
      t.map.on('click', function(event) {
        let targetLatLng = [event.latlng.lat, event.latlng.lng];
        let currentLatLng = [m.position.lat, m.position.lng];
        if (m.data.user === t.me && t.mypos) {
          requestAnimationFrame(t.animateMarkerWrapper(m, null, currentLatLng, targetLatLng));
        }else{
          requestAnimationFrame(t.animateMarkerWrapperNoCamera(m, null, currentLatLng, targetLatLng));
        }
        socket.send(JSON.stringify({lat: event.latlng.lat, lng: event.latlng.lng, me: t.me}))
      });
    },
  },
  beforeDestroy() {
     // socket.close()
    this.map.off('locationfound');
  },
}
</script>

<style scoped="">
@import url('../../public/assets/leaflet/prunecluster/LeafletStyleSheet.css');
#map {
  /*height: 94.3%;*/
  height: calc(100% - 51px);
  /*height: 100%;*/
  width: 100vw;
}
div#size, a#delete {
  position: absolute;
  right: 1em;
  top: 1em;
  background: white;
  color: black;
  padding: 0.4em;
  border-radius: 4px;
  z-index: 500;
}

.field{
  padding: 5px 0;
}

</style>
