<template>
  <div id="app">
    <navbar @auth="auth" @myprofile="myprofile" @map="map" @friends="friends" @WS="WS" @tg="tg" @groups="groups"></navbar>
    <auth v-if="state==='auth'" v-model:value="state_auth"></auth>
    <component4 v-else-if="state==='map'" @profile="profile" @myprofile="myprofile" id="map"></component4>
    <WS v-else-if="state==='WS'" @profile="profile" @myprofile="myprofile"></WS>
    <tg v-else-if="state==='tg' || state==='group_map'" @profile="profile" @myprofile="myprofile" @marker="marker"></tg>
    <profile v-else-if="state==='profile'" :prop="user" @map="map"></profile>
    <myprofile v-else-if="state==='myprofile'"></myprofile>
    <friends v-else-if="state==='friends'" @profile="profile"></friends>
    <groups v-else-if="state==='groups'" @group_map="group_map"></groups>
    <static-marker v-else-if="state==='marker'" @tg="tg"></static-marker>
  </div>
</template>

<script>
import component4 from './views/component4.vue'
import navbar from './views/navbar.vue'
import auth from './views/auth.vue'
import profile from './views/profile.vue'
import myprofile from './views/myprofile.vue'
import friends from './views/friends.vue'
import WS from './views/WS.vue'
import groups from "./views/groups.vue";
import tg from './views/tg.vue'
import socket from "./socket";
import {bus} from './eventBus'
import staticMarker from './views/staticMarker.vue'

export default {
  name: 'app',
  components:{component4, navbar,auth, profile, myprofile, friends, groups, WS, tg, staticMarker},
  data(){
    return{
      state:localStorage.getItem("state")!==undefined ? localStorage.getItem("state"): 'map',
      user:'',
      me:localStorage.getItem('me'),
      showModal:false,
      state_auth:null,
    }
  },
  mounted(){
    // socket.onopen = function() {console.log("Соединение установлено")};
    bus.$on('myprofile', this.myprofile)
    bus.$on('profile', this.profile)
    if(localStorage.state==null){localStorage.state='map'}
    if(localStorage.openHot==null){localStorage.openHot='✔'}
    // online true
    //  if (localStorage.getItem('me')!=null){
    //  this.$http.post(localStorage.getItem("s")+'/app/isOnline/', {me:localStorage.getItem('me'), online:true})
    //    .then((response) => {
    //    });
    //  }

    //online false
    let t=this
    window.onunload=function(){t.$http.post(localStorage.getItem("s")+'/app/offOnline/', {me:localStorage.getItem('me')})};
    // window.onbeforeunload=function(){
    //   t.$http.post(localStorage.getItem("s")+'/app/offOnline/', {me:localStorage.getItem('me')})
    //   return 'OK'
    //   };
  },
  methods:{
    map(){
      this.state='map'
      localStorage.state='map'
    },
    auth(state){
      this.state_auth=state
      this.state='auth'
    },
    profile(){
      this.state='profile'
    },
    myprofile(){
      this.state='myprofile'
      // localStorage.state='myprofile'
    },
    friends(){
      this.state='friends'
      // localStorage.state='friends'
    },
    groups(){
      this.state='groups'
    },
    WS(){
      this.state='WS'
      localStorage.state='WS'
    },
    tg(){
      this.state='tg'
      localStorage.state='tg'
    },
    group_map(){
      this.state='group_map'
    },
    marker(){
      this.state='marker'
    }
  },
 }
</script>

<style>
/*@import url('primeicons/primeicons.css');*/
@import url('../public/assets/style.css');
#app{
  height: 100%;
  /* margin:0px;
  padding:0px; */
  /* overflow:hidden; */
  /* height: calc(100% - 13px); */
}
body{
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

</style>
