import { defineStore } from 'pinia'

export const TgMapStore = defineStore('tg_map', {
  state: () => ({
    online: false,
    users: [],
    marker:null,
  }),

  actions: {
    addMarker(marker){
      this.marker = marker
    },
    addUser(user) {
      //console.log("add user")
      this.users.push(user)
    },
    delUser(user){
      this.users.splice(this.users.indexOf(user),1)
    },
    cleanUsers(){
      this.users.length = 0
    },
    updateUser(user){
      //console.log("update user")
      const filter_user = this.users.filter(item=>item.me === user.me)
      if(filter_user[0]){
        filter_user[0].lat = user.lat
        filter_user[0].lng = user.lng
      }
    },
    toggleOnline(){
      this.online = !this.online
    }
  },
})

export const TgDialogsStore = defineStore('tg_dialogs', {
  state: () => ({
    my_groups: [],
    all_groups: [],
    users: [],
  }),

  actions:{
    update_my_groups(data){
      const group = this.my_groups.filter(group=>group===data)[0]
      group.mapa = !group.mapa
    },
    add_my_groups(data){
      this.my_groups = data
    },
    add_all_groups(data){
      this.all_groups = data
    },
    add_users(data){
      this.users = data
    },
  }
})
