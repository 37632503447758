<template>
  <div class="main">
    <div class="container">
<!--      <form>-->
<!--        <div class="my-p">-->
<!--          <div class="p-float-label">-->
<!--            <InputText :class="{'p-invalid': v$.nick.$invalid && sub}" v-model="v$.nick.$model" autocomplete="username"/>-->
<!--            <label>{{$t('auth.nick')}}</label>-->
<!--          </div>-->
<!--          <small v-if="(v$.nick.$invalid) && sub || v$.nick.$pending.$response" class="p-error">{{$t('auth.valid.nick')}}</small>-->
<!--        </div>-->
<!--        <div class="my-p">-->
<!--          <div class="p-float-label">-->
<!--            <Password :class="{'p-invalid': v$.pass.$invalid && sub}" v-model="v$.pass.$model" :feedback="feedback" @keyup.enter="login" autocomplete="current-password"/>-->
<!--            <label>{{$t('auth.pass')}}</label>-->
<!--          </div>-->
<!--          <small v-if="(v$.pass.$invalid) && sub || v$.pass.$pending.$response" class="p-error">{{$t('auth.valid.pass')}}</small>-->
<!--        </div>-->
<!--        <div v-if="value==='reg'" class="my-p">-->
<!--          <div class="p-float-label">-->
<!--            <Password :class="{'p-invalid': v$.confirm.$invalid && sub}" v-model="v$.confirm.$model" :feedback="false" @keyup.enter="reg"/>-->
<!--            <label>{{$t('auth.confirm')}}</label>-->
<!--          </div>-->
<!--          <small v-if="(v$.confirm.$invalid) && sub || v$.confirm.$pending.$response" class="p-error">{{$t('auth.valid.confirm')}}</small>-->
<!--        </div>-->
<!--        <div class="my-p">-->
<!--          <Button v-if="value==='login'" class="p-button-rounded" @click="login">{{ $t('auth.login') }}</Button>-->
<!--          <Button v-if="value==='reg'" class="p-button-rounded" @click="reg">{{ $t('auth.reg') }}</Button>-->
<!--        </div>-->
<!--      </form>-->
      <!--            <vue-telegram-login mode="callback" telegram-login="MapaWSBot" @callback="yourCallbackFunction"/>-->
      <!--            <button class="btn btn-primary btn-orange button" @click="telegram">telegram</button>-->
      <!--            <form>-->
      <!--              <div id="phoneDiv">-->
      <!--                <label>phone number</label> <input disabled type="text" id="phone" />-->
      <!--                <button type="button" disabled id="phoneSend">Send</button>-->
      <!--              </div>-->
      <!--              <div id="codeDiv" style="visibility: hidden">-->
      <!--                <label>code you recieved</label> <input type="text" id="code" />-->
      <!--                <button type="button" id="codeSend">Send</button>-->
      <!--              </div>-->
      <!--              <div id="passDiv" style="visibility: hidden">-->
      <!--                <label>password</label> <input type="text" id="pass" />-->
      <!--                <button type="button" id="passSend">Send</button>-->
      <!--              </div>-->
      <!--            </form>-->
      <div class="my-p" v-if="state==='start'">
        <button v-if="value==='login'" class="tg_login_button" @click="signInTelegram">{{ $t('auth.tg.login_button') }}</button>
      </div>
      <div>
          <div id="phoneDiv" class="my-p" style="visibility: hidden" v-show="state==='phone'">
            <span class="p-float-label">
            <InputText id="phone"/>
            <label>{{$t('auth.tg.phone')}}</label>
          </span>
            <Button class="p-button-rounded send_button" id="phoneSend" @click="state='code'">{{ $t('auth.send') }}</Button>
          </div>

        <div id="codeDiv" class="my-p" style="visibility: hidden" v-show="state==='code'">
          <span class="p-float-label">
            <InputText id="code"/>
            <label>{{$t('auth.tg.code')}}</label>
          </span>
          <Button class="p-button-rounded send_button" id="codeSend" @click="state='password'">{{ $t('auth.send') }}</Button>
        </div>

        <div id="passDiv" class="my-p" style="visibility: hidden" v-show="state==='password'">
          <span class="p-float-label">
            <InputText id="pass"/>
            <label>{{$t('auth.pass')}}</label>
          </span>
          <Button class="p-button-rounded send_button" id="passSend">{{ $t('auth.send') }}</Button>
        </div>

      </div>

      <div v-if="loading" class="zero_data">
        <ProgressSpinner />
      </div>

    </div>
  </div>
</template>

<style scoped>
.send_button{
  margin-top: 10px;
}
.btn_tg{
  padding-bottom: 20px;
}
.my-p{
  padding: 15px 0;
}
.main {
  /*margin-top: 50px;*/
  display: flex;
  align-items: center; /* Выравнивание по центру по вертикали */
  justify-content: center; /* Выравнивание по центру по горизонтали */
  height: calc(100% - 100px); /* Занимает всю высоту страницы */
  /*max-width: 1200px;*/
}
.container{
  margin: 0 auto;
  text-align: center;
  width: 230px;
}
.tg_login_button {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 21px 11px;
  /*border-radius: 20px;*/
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  border-radius: 17px;
  background-color: #54a9eb;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  border: none;
  color: #fff;
  cursor: pointer;
}
</style>

<script>
//import {vueTelegramLogin} from 'vue-telegram-login'
//import "../../plugins/telegram3.js"
//import {telegram} from "../../plugins/telegram3";
//import {TelegramClient, sessions} from "../../plugins/telegram.js"
import bcrypt from 'bcryptjs';
import tg from "../telegram/tg";
import InputNumber from "primevue/inputnumber/InputNumber";
import InputMask from "primevue/inputmask/InputMask";
import Password from "primevue/password/Password";
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import ProgressSpinner from "primevue/progressspinner";


export default {
  components:{InputNumber, InputMask, Password, ProgressSpinner},
  setup(){
    return {v$:useVuelidate()}
  },
  validations(){
    return{
      nick:{required, maxLength: maxLength(30)},
      pass:{required, minLength: minLength(8)},
      confirm:{required, s(){return this.pass===this.confirm}},

      phone_tg:{required, minLength: minLength(10)},
      code_tg:{required, minLength: minLength(6)},
      pass_tg:{required, minLength: minLength(4)},
    }
  },
  props:{
    value:[String],
  },
  data(){
    return{
      nick:'',
      pass:'',
      confirm:'',
      sub:false,
      state:'start',
      loading:false,
    }
  },
  computed:{
    feedback(){
      if(this.value === 'login') return false
      else return true
    }
  },
  methods:{
    yourCallbackFunction (user) {console.log(user)},
    login() {
      let t = this
      t.sub=true
      if(!t.v$.nick.$invalid && !t.v$.pass.$invalid){
        t.$http.post(localStorage.getItem("s") + '/app/login/', {username: t.nick}).then((response) => {
          if (response.data != 'error') {
            bcrypt.compare(t.pass, response.data, function (err, result) {
              if (result == true) {
                localStorage.me=t.nick
                localStorage.mypos='true'
                localStorage.login='site'
                document.location.reload(true);
              } else {
                alert('Неверные данные!')
              }
            });
          } else {
            alert('Неверные данные!')
          }
        });
      }
    },
    reg(){
      let t = this;
      t.sub=true
      if(!t.v$.nick.$invalid && !t.v$.pass.$invalid && !t.v$.confirm.$invalid){
        bcrypt.hash(this.pass, 10, function(err, hash) {
          t.$http.post(localStorage.getItem("s")+'/app/reg/', {username:t.nick, password:hash}).then((response) => {
            if (response.data!='error'){
              // console.log(response.data)
              localStorage.me=response.data;
              localStorage.mypos='true'
              localStorage.login = 'site'
              document.location.reload(true);
            }
            else{
              alert('Такой ник уже есть, придумай другой!')
            }
          });
        });
      }
    },
    async signInTelegram() {
      this.state='phone'
      await tg.signInTelegram()
      this.loading = true
      const user = await tg.getMe();
      if(user){
        this.$http.post(localStorage.getItem("s")+'/tg/login/', {username:user.username,id:user.id}).then((res) => {
          console.log(res.data)
        })
        this.loading = false
        localStorage.me = user.username
        localStorage.mypos = 'true'
        localStorage.login = 'tg'
        localStorage.state = 'tg'
        document.location.reload(true);
      }
    }
  }
}
</script>



