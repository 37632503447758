<script>
  import tg from "../telegram/tg";

  export default {
   data(){
    return{
      chek:false,
      me:localStorage.mapa_me,
      nick:localStorage.getItem("user"),
      lat:'',
      lng:'',
      online:'',
      img:'',
      index:null,
      status:'',
      page:'',
    }
    },
    mounted(){
      this.initProfile();
    },
    methods:{
      // getChat(){
      //   this.$emit('chat');
      //   localStorage.setItem('img',this.img)
      // },
      async initProfile(){
        if(localStorage.mapa_login === 'site') {
          this.$http.post(localStorage.mapa_api + '/app/profile/', {nick: this.nick, me: this.me}).then((res) => {
            const data = res.data
            console.log(data)
            this.img = localStorage.mapa_api + data.image;
            this.status = data.status;
            this.chek = data.chek
            this.page = data.page
          });
        }else if(localStorage.mapa_login === 'tg'){
          if(this.nick.includes("fake-user")){
            this.img = localStorage.mapa_api+'/images/default.jpg'
          }else {
            this.img = await tg.getUserPhoto(await tg.getUser(this.nick))
          }
        }
    },
      addFriend(){
        this.$http.post(localStorage.mapa_api+'/app/friends/', {me:this.me, nick:this.nick, add_friend:'OK'})
            .then((response) => {
              this.chek=true
        });
      },
      delFriend(){
        this.$http.post(localStorage.mapa_api+'/app/friends/', {me:this.me, nick:this.nick, del_friend:'OK'})
            .then((response) => {
              this.chek=false
        });
      },
      getPos(){
        this.$http.post(localStorage.mapa_api+'/app/getPos/', {nick:this.nick})
            .then((response) => {
              console.log(response.data)
              if (response.data.online==true){
                let lat=response.data.lat;
                let lng=response.data.lng;
                localStorage.setItem('lat',lat)
                localStorage.setItem('lng',lng)
                localStorage.setItem('zoom','19')
                localStorage.setItem('getpos', true)
                this.$emit('map');
              }else{
                alert("User is offline!")
              }
            })
            .then((response)=>{
              localStorage.removeItem('getpos')
            });
      },
      getPage(){
        if (!this.page.match(/^https?:\/\//i)) {
          this.page = 'http://' + this.page;
        }
        window.open(this.page, '_blank');
      }
  },
  beforeDestroy(){
  }
}
</script>

<template>
<div class="main">
  <h1>{{nick}}</h1>
  <h3>{{status}}</h3>
  <img class="ava" :src="img"><br><br>
  <Button class="p-button-success" @click="addFriend" v-if="!chek">{{$t('profile.add_user')}}</Button>
  <Button class="p-button-danger" @click="delFriend" v-if="chek">{{$t('profile.delete_user')}}</Button>
  <Button class="" @click="getPos">{{$t('profile.on_map')}}</Button>
  <Button class="p-button-secondary" @click="getPage" v-if="page!=''">{{$t('profile.to_site')}}</Button>
  <!-- <button class="btn btn-info" @click="getChat">Send message</button> -->
</div>

</template>

<style scoped>
.main{
  text-align: center;
}
.ava{
  height: 300px;
  width: auto;
}
</style>


