<template>
<div>

  <div class="toggle_groups">
    <Button :style="state===0?{'background': '#4a76a8'}:{}" class="p-button-secondary p-button-sm" label="Созданные группы" @click="getDialogs(0)"/>
    <Button :style="state===1?{'background': '#4a76a8'}:{}" class="p-button-secondary p-button-sm" label="Мои группы" @click="getDialogs(1)"/>
    <Button :style="state===2?{'background': '#4a76a8'}:{}" class="p-button-secondary p-button-sm" label="Юзеры" @click="getUsers"/>
    <Button class="p-button-secondary p-button-sm" @click="confirm=true">⚠️</Button>
  </div>

  <div v-if="!loading">
    <div v-for="group of tgDialogStore.my_groups" :key="group.id" v-if="state===0">
        <div class="user-item">
          <div @click="activateMap(group)" class="user-item-container">
            <img :src="group.photo.url" alt="User Photo" class="user-photo">
            <div class="user-nick" :style="handleColor(group)">{{ group.title }}</div>
            <Button @click.stop="settings_toggle($event, group.id)" class="p-button-danger p-button-rounded p-button-sm p-button-raised delete-button">🛠️</Button>
            <OverlayPanel :ref="'settings'+group.id" :target="'settings'+group.id" appendTo="body" class="settings_menu">
              <p v-if="!group.mapa" class="select" @click="addGroup(group)">Добавить группу в mapa</p>
              <p v-if="group.mapa" class="select" @click="delGroup(group)">Удалить группу из mapa</p>
              <p v-if="group.id===room" class="select" @click="deactivateMap(group.id)">Выйти из карты группы</p>
            </OverlayPanel>
          </div>
        </div>
    </div>

    <div v-for="group of tgDialogStore.all_groups" :key="group.id" v-if="state===1">
      <div class="user-item">
        <div @click="activateMap(group)" class="user-item-container">
          <img :src="group.photo.url" alt="User Photo" class="user-photo">
          <div class="user-nick" :style="handleColor(group)">{{ group.title }}</div>
          <Button @click.stop="settings_toggle($event, group.id)" class="p-button-danger p-button-rounded p-button-sm p-button-raised delete-button">🛠️</Button>
          <OverlayPanel :ref="'settings'+group.id" :target="'settings'+group.id" appendTo="body" class="settings_menu">
            <p v-if="group.id===room" class="select" @click="deactivateMap(group.id)">Выйти из карты группы</p>
          </OverlayPanel>
        </div>
      </div>
    </div>

    <div v-for="user of tgDialogStore.users" :key="user.id" v-if="state===2">
        <div class="user-item">
          <div @click="activateMap(user)" class="user-item-container">
            <img :src="user.photo_url" alt="User Photo" class="user-photo">
            <div class="user-nick" :style="handleColor(user)">{{ user.first_name }}</div>
            <Button @click.stop="settings_toggle($event, user.id)" class="p-button-danger p-button-rounded p-button-sm p-button-raised delete-button">🛠️</Button>
            <OverlayPanel :ref="'settings'+user.id" :target="'settings'+user.id" appendTo="body" class="settings_menu">
              <p v-if="!user.mapa" class="select" @click="inviteUser(user)">Пригласить друга в mapa</p>
              <p v-if="room&&room.includes(user.id)" class="select" @click="deactivateMap(user.id)">Выйти из карты</p>
            </OverlayPanel>
          </div>
        </div>
    </div>
  </div>

  <div v-else class="zero_data">
    <ProgressSpinner />
  </div>

  <Dialog header="Внимание!" :visible.sync="confirm" :modal="true">
    Поднимайте вверх свои диалоги с пользователями и чаты в приложении телеграмм если их не видете тут. Значит там давно не было активности и мы не можем получить их. Просто напишите в вашем чате или диалоге с пользователем.
    Спасибо за понимание!
    <template #footer>
      <Button label="ОК" autofocus @click="confirm=false"/>
    </template>
  </Dialog>

</div>
</template>

<script>
import DataView from 'primevue/dataview'
import ProgressSpinner from 'primevue/progressspinner';
import tg from '../telegram/tg'
import {TgDialogsStore, TgMapStore} from "../store";
import OverlayPanel from "primevue/overlaypanel";
import {integer} from "@vuelidate/validators";
import Dialog from 'primevue/dialog';
import socket from "../socket";
export default {
  name: "groups",
  components:{DataView, ProgressSpinner, OverlayPanel, Dialog},
  setup() {
    const tgDialogStore = TgDialogsStore()
    const tgMapStore = TgMapStore()
    return {tgDialogStore,tgMapStore}

  },
  data(){
    return{
      loading:null,
      users:[],
      // my_groups:[],
      all_groups:[],
      state:localStorage.group_state!=undefined?Number(localStorage.group_state):2,
      confirm: false,
      room:localStorage.group_map_id!==undefined ? localStorage.group_map_id:null,
    }
  },
  mounted() {
    if(this.state===2 && this.tgDialogStore.users.length===0){
      this.getUsers()
    }else if(this.tgDialogStore.all_groups.length===0){
      if(this.state===0 || this.state===1){
        this.getDialogs(this.state)
      }
    }
  },
  computed:{
    // color(){
    //   group.mapa===true?{'color': '#4a76a8'}:{}
    //   if()
    // }
  },
  methods:{
    handleColor(data){
      if(this.room!==null&&this.room.includes(data.id)){
        return {'color': 'red'}
      }else if(data.mapa===true){
        return {'color': '#4a76a8'}
      }
    },
    async checkGroups(ids){
      const res = await this.$http.post(localStorage.s+'/tg/groups/', {nick:localStorage.me, ids:ids, check_groups:'OK'})
      return res.data
    },
    addGroup(group){
      this.$http.post(localStorage.s+'/tg/groups/', {nick:localStorage.me, group_id:group.id, add_group:'OK'}).then((res) => {
        if(res.ok===true){
          group.mapa=true
        }
      });
    },
    delGroup(group){
      this.$http.post(localStorage.s+'/tg/groups/', {nick:localStorage.me, group_id:group.id, del_group:'OK'}).then((res) => {
        if(res.ok===true){
          group.mapa=false
          if(this.room===group.id){
            this.room=null
            localStorage.removeItem('group_map_id')
          }
        }
      });
    },
    inviteUser(user){
      tg.sendMessage(user.id, user.access_hash, 'Привет! Приглашаю тебя в mapa.ws!')
    },
    mapUser(user){

    },
    settings_toggle(e, id){
      this.$refs['settings'+id][0].toggle(e)
    },
    async getDialogs(state){
      if(this.tgDialogStore.all_groups.length === 0){
        this.loading = true
        let res = await tg.getDialogs()
        console.log(res)
        console.log(res.chats.filter(chat => chat.creator === true))
        const all_groups = res.chats.filter(chat => chat._ !== 'chatForbidden')

        // console.log(all_groups.filter(chat => chat.creator === false))
        const ids = all_groups.map(obj => obj.id)
        const mapa_groups = await this.checkGroups(ids)
        console.log(mapa_groups)
        for (const group of all_groups){
          // console.log(group)
          group.mapa = false
          group.photo.url = await tg.getChannelPhoto(group)
          for (const item of mapa_groups){
            if(group.id==item.group_id){
              group.mapa = true
              console.log(group)
            }
          }
        }
        await this.tgDialogStore.add_my_groups(all_groups.filter(chat => chat.creator === true))
        await this.tgDialogStore.add_all_groups(all_groups.filter(chat => chat.creator === false))
        this.loading = false
      }
      this.state=state
      localStorage.group_state=state
    },
    async getUsers(){
      if(this.tgDialogStore.users.length === 0){
        this.loading = true
        let res = await tg.getDialogs()
        // console.log(res.users)
        const users = res.users.filter(user=>user.deleted === false&&user.username!==localStorage.me)
        for (const user of users){
          user.photo_url = await tg.getUserPhoto(user)
          // user.photo_url = localStorage.s+'/images/default.jpg'
        }
        this.tgDialogStore.add_users(users)
        this.loading = false
      }
      this.state=2
      localStorage.group_state=2
    },
    async activateMap(data){
      console.log(data)
      const t = this
      function addRoom(id){
        t.room = id
        localStorage.group_map_id = id
        socket.onclose = function(event) {
          if (event.wasClean) {
            console.log(`Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
          }else {
            console.log('Соединение прервано')
          }
        };
        t.tgMapStore.cleanUsers()
      }
      if(data.mapa===true){
        await addRoom(data.id)
        // t.$emit('group_map')
        window.location.reload()
      } else if(data._==='user'){
        console.log('user')
        const me = await tg.getMe()
        const list = [data.id, me.id].sort((x, y) => x - y)
        await addRoom(list[0]+list[1])
        window.location.reload()
      }
    },
    deactivateMap(id){
      this.room=null
      socket.onclose = function(event) {
        if (event.wasClean) {
          console.log(`Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
        }else {
          console.log('Соединение прервано')
        }
      }
      this.tgMapStore.cleanUsers()
      localStorage.removeItem('group_map_id')
    },
    handlerGroup(id){

    }
  }
}
</script>

<style scoped>
.toggle_groups{
  text-align: center;
  margin: 5px auto;
}

</style>
